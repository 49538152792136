import { z } from 'zod'
import { AccountDirectLinkedDeviceSchema, ClientLinkedAccessGroupSchema } from './Validation'
import { validateAccountUsername } from '~/utils/backend/accounts-api'

const languageCodes = z.union([
  z.literal('EN'),
  z.literal('DK'),
  z.literal('DU'),
  z.literal('ES'),
  z.literal('FI'),
  z.literal('FR'),
  z.literal('HU'),
  z.literal('NL'),
  z.literal('PL'),
  z.literal('RU'),
  z.literal('SK'),
])

const grantCategory = z.union([
  z.literal('SYSTEM'),
  z.literal('MANAGEMENT_TOOL'),
  z.literal('WEB_PORTAL'),
  z.literal('OPTILINK_API'),
])

const grantType = z.union([
  z.literal('WRITE'),
  z.literal('READ'),
  z.literal('DENY'),
])

export const DateFormat = z.union([
  z.literal('DD-MM-YYYY HH:mm'),
  z.literal('MM-DD-YYYY HH:mm'),
  z.literal('YYYY-MM-DD HH:mm'),
])

export const LanguageSchema = z.object({
  code: languageCodes.default('EN'),
  label: z.string().min(1, { message: 'Label format is required' }).default('English'),
  id: z.string().nullable(),
}).nullable()

export const RoleSchema = z.object({
  id: z.string().nullable(),
  name: z.string().min(1, { message: 'Name is required' }),
  default: z.boolean(),
  policies: z.string().array().min(1, { message: 'Policy is required' }),
})

export const GrantDataSchema = z.object({
  name: z.string(),
  category: grantCategory,
  type: grantType,
})

export const SecurityPolicySchema = z.object({
  id: z.string().nullable(),
  name: z.string().min(1, { message: 'Name is required' }),
  default: z.boolean(),
  grants: GrantDataSchema.array(),
})

export const AccountSettingsSchema = z.object({
  defaultPcNumber: z.number(),
  language: LanguageSchema,
  dateFormat: DateFormat.default('DD-MM-YYYY HH:mm'),
})

export const PersonalSettingsSchema = z.object({
  username: z.string(),
  accountSettings: AccountSettingsSchema,
  email: z.string().nullable().refine(value => !value || z.string().email().safeParse(value).success, {
    message: 'Invalid email',
    path: ['email'],
  }),
  use2FA: z.boolean({ message: 'use2FA is required' }),
})

export const AccountSchema = z.object({
  id: z.string().nullable(),
  username: z.string().min(1, 'Username is required'),
  password: z.string().nullable(),
  email: z.string().nullable().refine(value => !value || z.string().email().safeParse(value).success, {
    message: 'Invalid email',
    path: ['email'],
  }),
  demoUser: z.boolean(),
  use2FA: z.boolean().nullable(),
  secret: z.string().nullable(),
  isLocked: z.boolean().default(false),
  locked: z.boolean().default(false),
  enableExport: z.boolean().default(false),
  settings: AccountSettingsSchema,

  roles: z.string().array().default([]),
  linkedRoles: z.string().array().default([]),
  domains: z.string().array().default([]),
  pcNumbers: z.number().array().min(1, { message: 'Allowed pc numbers are required' }).max(99, { message: 'Max allowed pc numbers are 99' }),
  minBufferInterval: z.number(),
}).superRefine(async (arg, ctx) => {
  if (arg.id === null) {
    if (!arg.password) {
      ctx.addIssue({
        path: ['password'],
        message: 'Password is required',
        code: z.ZodIssueCode.custom,
      })
    } else {
      if (arg.password.length < 8) {
        ctx.addIssue({
          path: ['password'],
          message: 'Password must have at least 8 characters',
          code: z.ZodIssueCode.custom,
        })
      }
    }
    if (arg.username !== '') {
      const isValid = await validateAccountUsername(arg.id, arg.username).then(r => r.data).catch(() => false)
      if (!isValid) {
        ctx.addIssue({
          path: ['username'],
          message: 'Username already exists',
          code: z.ZodIssueCode.custom,
        })
      }
    }
  }
})

export const AccountSaveRequestSchema = z.object({
  account: AccountSchema,
  linkedDevices: AccountDirectLinkedDeviceSchema.array(),
  linkedAccessGroups: ClientLinkedAccessGroupSchema.array(),
})
